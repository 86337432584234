import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PageLoader from "../components/PageLoader";

const Login = lazy(() => import("../pages/authentication/Login"));
const ForgotPassword = lazy(() =>
  import("../pages/authentication/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../pages/authentication/ResetPassword")
);

const PublicRoute = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword/:email/:otp" element={<ResetPassword />} />
      </Routes>
    </Suspense>
  );
};

export default PublicRoute;
