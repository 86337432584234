import axios from "axios";
export const Helper = {
  PostData: async (url, obj) => {
    try {
      return await axios.post(url, obj, {
        headers: {
          Authorization: `Bearer ${
            window.localStorage.getItem("userToken") ||
            window.sessionStorage.getItem("userToken")
          }`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  DeleteData: async (url) => {
    try {
      return await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${
            window.localStorage.getItem("userToken") ||
            window.sessionStorage.getItem("userToken")
          }`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  GetData: async (url) => {
    try {
      return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${
            window.localStorage.getItem("userToken") ||
            window.sessionStorage.getItem("userToken")
          }`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  PostFormData: async (url, obj) => {
    try {
      return await axios.post(url, obj, {
        headers: {
          Authorization: `Bearer ${
            window.localStorage.getItem("userToken") ||
            window.sessionStorage.getItem("userToken")
          }`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  },
};
export const S3_URL = "https://askportia.s3.amazonaws.com/";
export const validateFields = async (validationSchema, values) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    return {};
  } catch (validationErrors) {
    const errors = {};
    validationErrors.inner.forEach((error) => {
      if (error.path) {
        errors[error.path] = error.message;
      }
    });
    return errors;
  }
};
export const shortenSentence = (sentence) => {
  const words = sentence?.trim()?.split(/\s+/);
  if (words.length > 4) {
    return words?.slice(0, 4).join(" ") + "...";
  } else {
    return sentence;
  }
};
export const localDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
};
export const capitalizeEveryWord = (sentence) => {
  const words = sentence?.split(" ");
  const capitalizedWords = words?.map((word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  });
  const capitalizedSentence = capitalizedWords?.join(" ");
  return capitalizedSentence;
};
export const formatDateToLocalISOString = (date) => {
  if (date) {
    const offsetInMinutes = date.getTimezoneOffset();
    const localISOString = new Date(
      date.getTime() - offsetInMinutes * 60000
    ).toISOString();
    return localISOString.split("T")[0];
  }
};

export const testAPI = () => {
  return {
    recipeName: "Burger",
    description: "It is not good to have",
    time: "45",
    totalServing: "3",
    caloryPerServing: "110",
    recipeImage: ['1706539730903.png'],
    nutritions: [
      {
        name: "protein",
        value: "6",
      },
      {
        name: "fat",
        value: "26",
      },
      {
        name: "carbohydrate",
        value: "6",
      },
    ],
    ingredients: [
      {
        name: "1 Slice Turkey Bacon, cut into small pieces",
        value: "1.7",
      },
      {
        name: "1 clove Garlic, minced",
        value: "1.8",
      },
      {
        name: "1 lb Mustard Greens, tough stems discarded, roughly chopped",
        value: "0.7",
      },
      {
        name: "1 tsp. Salt (optional)",
        value: "0.7",
      },
      {
        name: "Pepper to taste",
        value: "0.7",
      },
      {
        name: "1/2 cup Low Sodium Chicken broth",
        value: "0.7",
      },
      {
        name: "1 TBSP Lemon juice",
        value: "0.7",
      },
    ],
    instructions: [
      "heat a large amount of times",
      "heat a large amount of times",
      "heat a large amount of times",
      "heat a large amount of times",
      "heat a large amount of times",
    ],
    comments: ["heat a large amount of times hhhuiy this is not good enough","heat a large amount of times","heat a large amount of times"],
    url: "hhhh.com",
  };
};

