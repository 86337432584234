export const url = "https://admin.askportia.ai/api/";
//export const url = "http://192.168.18.254:3001/";
export const API_URL = {
  LOGIN: `${url}adminLogin`,
  PROFILE: `${url}adminFetchProfile`,
  DASHBOARD: `${url}adminDashBoardapi`,
  FORGOT_PASSWORD: `${url}adminforgotPassword`,
  RESET_PASSWORD: `${url}resetPassword`,
  CHANGE_PASSWORD: `${url}adminChangePassword`,
  CHANGE_USER_PASSWORD: `${url}changePassword`,
  USER_LIST: `${url}adminGetUserList`,
  DIETICIAN_LIST: `${url}adminGetDietitianNameList`,
  VIEW_USER: `${url}adminViewUser`,
  DELETE_USER: `${url}adminDeleteUserStatus`,
  FETCH_USER_DIARY: `${url}adminFetchUserDiary`,
  FETCH_USER_QUESTION: `${url}adminFetchUserAnswer`,
  UPDATE_USER: `${url}adminUpdateUserProfile`,
  BLOCK_USER: `${url}adminChangeUserStatus`,
  ADD_USER: `${url}adminUserSignup`,
  SEND_NOTIFICATION: `${url}sendAdminNotification`,
  GET_NOTIFICATION: `${url}getadminNotification`,
  CLEAR_NOTIFICATION: `${url}clearNotification`,
  ADD_RECIPE: `${url}adminInsertRecipe`,
  ADD_VIDEO: `${url}adminInsertVideo`,
  RECIPE_LIST: `${url}adminGetRecipeList`,
  VIEW_RECIPE: `${url}adminViewRecipe`,
  EDIT_RECIPE: `${url}adminEditRecipe`,
  DELETE_RECIPE: `${url}adminDeleteRecipeStatus`,
  VIDEO_LIST: `${url}adminGetVideoList`,
  VIEW_VIDEO: `${url}adminGetVideo`,
  EDIT_VIDEO: `${url}adminEditVideo`,
  DELETE_VIDEO: `${url}adminDeleteVideoStatus`,
  ADD_DIET: `${url}adminCreateDiet`,
  DIET_LIST: `${url}adminReadAllUserDiet`,
  DIET_LIST_USER: `${url}adminReadSingleUserDiet`,
  EDIT_DIET: `${url}adminEditDiet`,
  VIEW_DIET: `${url}adminReadDiet`,
  DELETE_DIET: `${url}adminDeleteDietStatus`,
  DELETE_DIET_USER: `${url}adminDeleteDietUserStatus`,
  ADD_MEAL: `${url}adminCreatePlan`,
  ADD_MEALCSV: `${url}insertMeal`,
  MEAL_LIST: `${url}adminReadAllUserPlan`,
  MEAL_LIST_USER: `${url}adminReadSingleUserMEAL`,
  EDIT_MEAL: `${url}adminEditPlan`,
  VIEW_MEAL: `${url}adminReadPlan`,
  DELETE_MEAL: `${url}adminDeletePlanStatus`,
  QUESTION: `${url}getAdminQuestions`,
  UPDATE_QUESTION: `${url}adminUpdateQuestion`,
  ADD_QUESTION: `${url}insertQuestion`,
  QUESTION_ORDER: `${url}changeQuestionOrder`,
  FETCH_SUB_CATEGORY_LIST: `${url}fecthQuestionariesType`,
  FETCH_CATEGORY_LIST: `${url}fecth_section`,
  FETCH_CATEGORY_DETAILS: `${url}admin_fetch_single_section`,
  FETCH_SECTION_QUESTION_LIST: `${url}fetchQuestionOfSection`,
  FETCH_SUB_CATEGORY_DETAILS: `${url}admin_fetch_single_subSection`,
  ADD_CATEGORY: `${url}insert_section`,
  UPDATE_CATEGORY: `${url}adminUpdate_section`,
  ADD_SUB_CATEGORY: `${url}insert_question_type`,
  DELETE_CATEGORY: `${url}admin_delete_section`,
  DELETE_SUB_CATEGORY: `${url}admin_delete_subSection`,
  UPDATE_SUB_CATEGORY: `${url}adminUpdate_sub_section`,
  CREATE_ROLE: `${url}insertRole`,
  FETCH_ROLES: `${url}fetchAllRole`,
  FETCH_ROLE_DETAILS: `${url}fetchRole`,
  EDIT_ROLE: `${url}editRole`,
  DELETE_ROLE: `${url}deleteRole`,
};
