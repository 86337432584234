import { Sentry } from "react-activity";

const PageLoader = () => {
  return (
    <div className="loaderdiv">
      <Sentry color="#87D234" size={40} speed={1} animating={true} />
    </div>
  );
};

export default PageLoader;
